@tailwind base;
@tailwind components;
@tailwind utilities;

/* add the code bellow */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.App {
  text-align: center;
}
/* styles.css */
body {
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  /* display: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.dropDownInput {
  /* background-color: #1c1f2b;  */
  color: white;
  border: none;
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
}

.dropDownInput option {
  background-color: #1c1f2b;
  color: white;
}

.userTableContainer.archived {
  opacity: 0.5;
}

.ctTable {
  th:first-child,
  td:first-child {
    text-align: left !important;
  }
  th.alignLeft,
  td.alignLeft {
    text-align: left !important;
  }
  td,
  th {
    text-align: right !important;
    min-width: 100px !important;
  }

  td.ctwidth,
  th.ctwidth {
    min-width: 250px !important;
  }

  td.alignRight,
  th.alignRight {
    text-align: right !important;
  }

  td:first-child {
    text-align: left !important;
  }
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

/* Custom dropdown container */
.custom-dropdown-select {
  background-color: #222f4c;
  /* border: 1px solid #3CC8C8; */
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes border-slide {
  0% {
    border-width: 3px 0 0 0;
  }
  25% {
    border-width: 3px 3px 0 0;
  }
  50% {
    border-width: 0px 3px 3px 0;
  }
  75% {
    border-width: 0 0px 3px 3px;
  }
  100% {
    border-width: 0 0 3px 3px;
  }
}

/* .gradient-border {
  position: relative;
  background: linear-gradient(90deg, #D724FC 12%, #7770FF 100%);
  border: 2px solid transparent;
  border-radius: 8px; 
  z-index: 1;
  background-clip: padding-box; 
  overflow: hidden; 
}

.gradient-border::before {
  content: '';
  border-radius: 8px;
  background-size: 300% 300%;
  animation: gradient-move 3s linear infinite;
} */

@keyframes gradient-move {
  0% {
    border-width: 3px 0 0 0;
  }
  25% {
    border-width: 3px 3px 0 0;
  }
  50% {
    border-width: 3px 3px 3px 0;
  }
  75% {
    border-width: 3px 3px 3px 3px;
  }
  100% {
    border-width: 3px 3px 3px 3px;
  }
}
