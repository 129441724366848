
.mainLogin {
    /* border:2px red solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height:97vh; */
    width: 100%;
}

.UsenameField {
    background-color: #FFFFFF;
    border-radius: 16px;
    /* height: 42px; */
    padding: 10px!important;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    /* padding-left: 16px; */
    /* width:calc(100% - 30px); */
}

.TextArea {
    background-color: #FFF;
    border-radius: 16px;
    min-height: 100px;
    border: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding-left: 16px;
    padding-top: 16px;
    width:calc(100% - 16px);
}

.TextArea::placeholder {
    color: #A0A0A0;             /* Set the placeholder text color */
    font-size: 16px;
    font-weight: 500;
    /* padding-left: 12px; */
}

.TextArea:focus {
    outline: none;
}

.UsenameField::placeholder {
    color: #A0A0A0;             /* Set the placeholder text color */
    font-size: 16px;
    font-weight: 500;
    /* padding-left: 12px; */
}

.UsenameField:focus {
    outline: none;
}

.LoginButton {
    height: 36px;
    background-color: #7636CE;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding:0px 12px;
    cursor: pointer;
    width: 100%;
}

.password-toggle-btn {
    background: none;
    border: none;
    color: #A0A0A0;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.fullImage {
    width: 100%;
    /* height: 100%; */
    /* object-fit: cover; */
  }

  .loginNote {
    font-size:12px;
    font-weight: 400;
    color:#FFF;
  }

  .error {
    color:red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .error {
    color:white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .hello {
    font-size: 1.5rem;
    font-weight: 700;
    color: #FFFFFF;
  }
  .greet {
    font-size: 0.8rem;
    font-weight: 400;
    color: #FFFFFF;
  }

  @media screen and (max-width: 768px) {
    .leftContainer {
      display: none
    }
    .loginContent {
      flex:1;
      display:'flex' !important;
      justify-content:'center' !important;
      align-items:'center' !important;
      height:'100vh' !important;
    }
    .mainLogin {
      /* border:2px red solid; */
      display: flex;
      justify-content: center;
      align-items: center;
      height:100vh;
      width: 100%;
      background: linear-gradient(153.81deg, rgba(26, 26, 27, 0.5) 50%, #7C7C81 120.41%);
  }
  .mobileLogo {
    margin-bottom:50px;
  }
  .loginContainer {
    /* width: 300px !important; */
    /* background-color: #1C1C1C; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap:18px;
    justify-content: center;
    align-items: center;
    padding:36px 48px 36px 36px;
    /* max-height: 97vh; */
}
  }

  @media screen and (min-width: 767px) {
    .leftContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex:20%;
      height:100vh;
      background: radial-gradient(97.89% 97.89% at 69.24% 2.11%, #4A4A4E 0%, #141415 100%) !important;
    }
    .loginContent {
      flex:1;
      display:'flex' !important;
      justify-content:'center' !important;
      align-items:'center' !important;
      height:'100vh' !important;
      background-color:'#3E3E3E' 
    }
    .mainLogin {
      /* border:2px red solid; */
      display: flex;
      justify-content: center;
      align-items: center;
      /* height:97vh; */
      width: 100%;
  }
  .mobileLogo {
    display: none;
  }
  .loginContainer {
    /* width: 300px !important; */
    /* background-color: #1C1C1C; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap:18px;
    justify-content: center;
    align-items: center;
    padding:36px 130px 36px 130px;
    max-height: 97vh;
}
  }
