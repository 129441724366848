@media screen and (max-width:1920px) {
    .mainContainer {
        flex: 1;
        display:flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }
    .contentContainer {

    }
}

@media screen and (min-width:1920px) {
    .mainContainer {
        flex: 1;
        display:flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }
    .contentContainer {
        max-width: 1920px;
        margin: 0 auto;
    }
}