.letter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cccc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 3em;
  font-weight: 600;
  color: black;
  padding: 1em;
}

.button-container {
  display: flex;
  gap: 1em;
}

.addFundsBtn {
  background: linear-gradient(272.44deg, #0eeea8 4.31%, #7770ff 129.99%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: flex;
  align-items: center;
  gap: 0.5em;
  height: fit-content;
  padding: 10px 32px;
  border-radius: 50px;
  color: white;
}

.loginReset {
  background-image: linear-gradient(to right, #d724fc, #7770ff);
  height: fit-content;
  padding: 10px 32px;
  border-radius: 50px;
  color: white;
}

.records-details-container {
  display: flex;
  width: 100%;
  color: white;
  background: #212f4c;
  gap: 2em;
}
.records-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  background: #212f4c;
  padding: 4em;
  gap: 2em;
}

.record-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 2em;
}

.count-record-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.count-container {
  flex: 1;
  background: #37435e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  border-radius: 30px;
  /* height: 200px; */
}

.count-label {
  font-weight: 600;
  font-size: 1em;
}

.value-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.count-container.balance {
  width: 300px;
}

.amount {
  font-size: 2em;
  font-weight: 600;
}

.amount.blue {
  color: #3cc8c8;
}

.amount.red {
  color: #fd3549;
}

.amount.trade {
  margin-bottom: 0.5em;
}

.count-container {
  width: 200px;
}

.records-left,
.records-right {
  display: flex;
  flex-direction: column;
  gap: 2em;
  flex: 1;
}

.edit-btn {
  font-size: 0.5em;
  text-align: center;
  color: white!important;
  /* background: #2b3c5e; */
  background: linear-gradient(90deg, #D724FC 12%, #7770FF 100%);
  border-radius: 30px;
  padding: 0.5em 2em;
  color: #959eaf;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.adduserInput {
  border: 1px solid #8692a6;
  color: #f6f6f6;
  padding: 16px 16px;
  border-radius: 100px;
  font-size: 1rem;
  font-weight: 500;
  width: -webkit-fill-available;
}
.adduserAsset {
  background-color: transparent;
  color: #f6f6f6; /* Set the text color */
  padding: 16px 16px; /* Adjust padding as needed */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 1rem; /* Set font size */
  font-weight: 500; /* Set font weight */
  width: -webkit-fill-available;
  border-radius: 100px;
}
.adduserInput:focus, .adduserAsset:focus {
  outline: none;
}

.dropdown {
  width: 100px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.phone {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddUserButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 160px;
  height: 36px;
  background: linear-gradient(
    270deg,
    rgb(14, 238, 168) 12.01%,
    rgb(119, 112, 255) 100%
  );
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 30px;
  padding: 30px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  /* .count-container {
    flex: 0!important;
    min-width: 48%!important;
  } */
  .amount {
    font-size: 1.5em;
  }
  .records-details-wrapper {
    padding: 2em !important;
    .count-record-container {
      padding: 0 !important;
      flex-direction: column;
    }
    .record-header {
      font-size: 1rem !important;
    }
  }

  .mobileTable {
    /* max-width:330px; */
    overflow-x: auto; /* Display horizontal scrollbar */
    white-space: nowrap;
  }
}

.userDataTable .table tr {
  display: flex;
  color: #a9acbb;
  font-size: 0.7rem;
  font-weight: 400;
  border-bottom: 0.05px #494a4e solid;
}
@media (max-width: 640px) {
  .common .form-container {
    background: #222f4c !important;
    padding: 2rem !important;
  }
  .userTable {
    padding: 1rem !important;
  }
  .userDataTable .table tr {
    display: flex;
    flex-wrap: wrap;
    color: #a9acbb;
    font-size: 0.7rem;
    font-weight: 400;
    border-bottom: 0.05px #494a4e solid;
  }
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  padding: 16px;
  text-align: center;
}
.table td {
  padding: 12px; /* Adjust padding as needed */
  text-align: left;
}

.table th {
  color: #878787;
  font-size: 18px;
  font-weight: 500;
}

/* Adjust width and spacing between columns */
.table th,
.table td {
  /* width: calc(100% / 3); */
}

/* Alternative approach: Equal spacing with flexbox */
.table tr {
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.table th,
.table td {
  flex: 1;
  text-align: left;
}

@media screen and (max-width: 767px) {
  /* .userTable {
    background: #152445 !important;
    padding: 0 !important;
  } */
  .form-container {
    padding: 1.5em !important;
    .record-header {
      font-size: 1.3rem;
    }
    .details-value {
      font-size: 1.1rem;
    }
  }
  .common .record-header {
    font-size: 1.5rem;
  }
  .common .details-value {
    font-size: 1rem;
  }
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    /* width: 100%; */
  }

  .table tr {
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: #212f4c;
    border-radius: 8px;
    padding: 12px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    text-align: right;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.userDataTableHeader {
  font-size: 2rem;
  font-weight: 500;
  color: #33cccc !important;
  text-transform: capitalize;
}

.skeleton-container {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.skeleton {
  /* background-color: #f0f0f0; */
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.skeleton-line {
  height: 20px; /* Adjust the height of each loading line */
  margin-bottom: 10px;
  background-color: #252525;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.Success {
  background: linear-gradient(
    270deg,
    rgb(14, 238, 168) 12.01%,
    rgb(119, 112, 255) 100%
  );
}

.Pending {
  background: linear-gradient(270deg, #d724fc 12.01%, #7770ff 100%);
}

.Success,
.Pending {
  margin: 1em;
  padding: 5px 0px !important;
  width: fit-content;
  border-radius: 40px;
}

.table-form-container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  gap: 1em;
}

.userTable,
.form-container {
  background: #212f4c;
  flex: 1;
  border-radius: 10px;
}

.userTable {
  padding: 2em;
  gap: 1em;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 3em 4em;
}

.record-title {
  color: white;
}

.table-btn-container {
  display: flex;
  gap: 1em;
}

.searchInput {
  padding: 8px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background-color: #292929;
  height: fit-content;

  @media (max-width: 767px) {
    padding: 8px;
    border-radius: 8px;
    border: none;
    color: #fff;
    background-color: #292929;
    width: 100% !important;
  }
}

.searchInput:focus {
  outline: none;
}

.gradientbutton {
  padding: 6px 24px;
  color: #fff;
  border-radius: 30px;
}
.profit-gradient {
  background: linear-gradient(272.44deg, #0eeea8 4.31%, #7770ff 129.99%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.loss-gradient {
  background: linear-gradient(90deg, #fc2465 0%, #ff004c 100%);
}

.details-header {
  color: var(--White-80, #ffffffcc);
  font-size: 15px;
  font-weight: 400;
}
.msg .details-header , .msg .details-value{
  font-size: 15px;
}

.details-value {
  color: var(--White-80, #ffffffcc);
  font-size: 24px;
  font-weight: 400;
}

.client-message-width {
  width: 600px;
  min-width: 600px;
  /* max-width: 600px; */
  word-break: break-word; /* Ensures long text wraps */
}

/* Class to limit text to 2 lines with ellipsis */
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3rem; /* Adjust based on font-size and line height */
}

/* Full view for expanded content */
.truncate-text.expanded {
  display: block;
  max-height: none;
}
