.name {
    color: #DDDDDD;
    font-weight: 500;
    font-size: 18px;
}

.sideBar{
    /* width: 16%; */
    height:calc(100vh);
    padding: 0px 24px;
}

.logoTitle {
    padding:24px 0px 0px 0px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
}

.card {
    padding: 12px;
    cursor: pointer;
}

.activeCard {
    background-color: #E31892;
    padding: 12px;
    border-radius: 16px;
    cursor: pointer;
}

.flexCol{
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.alignCenter {
    justify-content: center;
    align-items: center;
}

.g12 {
    gap: 12px;
}

.g10 {
    gap: 10px;
}

.splitCards{
    justify-content: space-between;
    height: 87vh;
}

@media screen and (min-width:768px) {
    .noDesktop {
        display: none;
    }
    .sideBar{
        /* width: 16%; */
        height:calc(100vh);
        padding: 0px 24px;
        background-color: #080808;
    }
}

@media screen and (max-width:767px) {
    .noMobile {
        display: none;
    }
    .hamburger-menu {
        position: absolute; /* Absolute positioning */
        top: 27px; /* Adjust as needed */
        left: 10px; /* Adjust as needed */
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
      }
      
      .line {
        width: 100%;
        height: 3px;
        background-color: #fff;
      }
      
      .line.close {
        position: absolute;
        transform: rotate(45deg);
      }
      
      .line.close:nth-child(2) {
        display: none;
      }
      
      .line.close:nth-child(3) {
        transform: rotate(-45deg);
      }
      .navbar {
        position: relative;
      }

      .sideBar{
        /* width: 16%; */
        height:calc(100vh);
        padding: 0px 24px;
        background-color: #080808;
    }

    .logoTitle {
        padding:12px 24px 12px 12px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 24px;
    }
      
}