.userdetails {
  width: 100%;
}

.addusername {
  font-size: 1rem;
  font-weight: 700;
  color: #fc287f;
}

.adduserfieldName {
  color: #696f79;
  font-size: 1rem;
  font-weight: 500;
}

.adduserInput {
  border: 1px solid #8692a6;
  background-color: transparent;
  color: #f6f6f6; /* Set the text color */
  padding: 16px 16px; /* Adjust padding as needed */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 1rem; /* Set font size */
  font-weight: 500; /* Set font weight */
  width: -webkit-fill-available;
}
.adduserAsset {
  background-color: transparent;
  color: #f6f6f6; /* Set the text color */
  padding: 16px 16px; /* Adjust padding as needed */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 1rem; /* Set font size */
  font-weight: 500; /* Set font weight */
  width: -webkit-fill-available;
  border-radius: 100px;
}
.adduserInput:focus, .adduserAsset:focus {
  outline: none;
}

.AddUserField {
  background-color: #080808;
  border-radius: 16px;
  height: 42px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-left: 24px;
  width: 100%;
}
.AddUserField::placeholder {
  color: #a0a0a0; /* Set the placeholder text color */
  font-size: 16px;
  font-weight: 500;
  /* padding-left: 12px; */
}

.AddUserField:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .AddUserContainer {
    min-width: 600px !important;
    background-color: #161617;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    align-items: flex-start;
    padding: 48px;
    max-height: 97vh;
    box-shadow: 0px 10px 60px 0px #7636ce80;
  }
  .cancelButton {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px #00000014;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0px 12px;
    cursor: pointer;
    min-width: 160px;
  }
  .AddUserButton {
    min-width: 160px;
    height: 36px;
    background-color: #7636ce;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0px 12px;
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .AddUserButton {
    font-size: 10px !important;
    padding: 10px !important;
  }
  .AddUserContainer {
    /* max-width: 250px !important; */
    width: 100% !important;
    background-color: #1c1c1c;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    align-items: center;
    /* padding:36px 48px 36px 36px; */
    padding: 12px;
    max-height: 97vh;
  }
  .userdetails {
    width: 90%;
  }
  .cancelButton {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px #00000014;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0px 12px;
    cursor: pointer;
    /* min-width: 160px; */
  }
  .AddUserButton {
    height: 36px;
    background-color: #7636ce;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0px 12px;
    cursor: pointer;
  }
}
