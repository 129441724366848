.gradient-text {
  background: linear-gradient(90deg, #7770ff 0%, #3cc8c8 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
  font-size: 32px; /* Adjust as needed */
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.vertical-gradient-text {
  background: linear-gradient(180deg, #ffffff 69%, #4d4a7f 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
  font-size: 44px; /* Adjust as needed */
  font-weight: bold;
  display: inline-block;
  background-size: 100%; /* Ensures full coverage */
  background-position: center;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.singleline-gradient-text {
  background: linear-gradient(180deg, #ffffff 19.5%, #7374b1 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari compatibility */
  color: transparent;
  font-size: 36px; /* Adjust as needed */
  font-weight: bold;
  display: inline-block;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.explore-btn {
  background: linear-gradient(90deg, #4d44ff 0%, #d300ff 88.5%) !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-size: large !important;
  font-weight: 500 !important;
  padding: 8px 24px !important;
  text-transform: capitalize !important;

  @media screen and (max-width: 768px) {
    font-size: small !important;
    padding: 2px 8px !important;
  }
}

.getstarted-btn {
  background: linear-gradient(
    76.84deg,
    #7770ff 13.58%,
    #3cc8c8 89.71%
  ) !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-size: large !important;
  font-weight: 500 !important;
  padding: 8px 24px !important;
  text-transform: capitalize !important;

  @media screen and (max-width: 768px) {
    font-size: small !important;
    padding: 2px 8px !important;
  }
}

.contact-btn {
  background: linear-gradient(
    76.84deg,
    #7770ff 13.58%,
    #3cc8c8 89.71%
  ) !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-size: large !important;
  font-weight: 500 !important;
  padding: 8px 24px !important;
  text-transform: capitalize !important;

  @media screen and (max-width: 768px) {
    font-size: large !important;
    padding: 2px 8px !important;
  }
}

.course-card {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2rem;
  padding: 100px 1rem 50px 1rem;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 2px solid var(--Color-stroke, #9000ff54);
  border-radius: 10px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    padding: 100px 12px 50px 12px;
  }
}

/* .course-card:hover {
    min-width: 200px;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 2rem;
    padding: 100px 36px 50px 36px;
    background: linear-gradient(180deg, rgba(145, 0, 255, 0) 0%, rgba(145, 0, 255, 0.17) 100%);
    border-width: 2px;
    border-style: solid;
    border-image-source: linear-gradient(90deg, #D724FC 12%, #7770FF 100%);
    border-image-slice: 1;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  } */

.course-card.isGradient {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2rem;
  padding: 100px 1rem 50px 1rem;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border-radius: 10px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    padding: 100px 12px 50px 12px;
  }
}

/* Gradient Border Animation */
.gradient-border {
  border-radius: 16px;
  border: 3px solid transparent;
  background: linear-gradient(#0e143a, #0e143a) padding-box,
    linear-gradient(90deg, #d724fc, #7770ff) border-box;
  animation: gradient-border-animation 4s linear infinite;
}

/* @keyframes gradient-border-animation {
  0% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 0%, #d724fc 25%);
  }
  25% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 25%, #7770ff 50%);
  }
  50% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #7770ff 50%, #d724fc 75%);
  }
  75% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #7770ff 75%, #d724fc 100%);
  }
  100% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 0%, #d724fc 100%);
  }
} */
@keyframes gradient-border-animation {
  0% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 0%, #d724fc 25%);
    border-width: 0 0 0 0;
  }
  25% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 25%, #7770ff 50%);
    border-width: 4px 0 0 0;
  }
  50% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #7770ff 50%, #d724fc 75%);
    border-width: 4px 4px 0 0;
  }
  75% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #7770ff 75%, #d724fc 100%);
    border-width: 4px 4px 4px 0;
  }
  100% {
    background: linear-gradient(#0e143a, #0e143a) padding-box,
      linear-gradient(90deg, #d724fc 0%, #d724fc 100%);
    border-width: 4px 4px 4px 4px;
  }
}

.top-icon {
  position: absolute;
  top: -40px; /* Moves the icon above the card */
  left: 50%;
  transform: translateX(-50%); /* Centers the icon horizontally */
  background-color: #86d2d2; /* Icon background color */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes the icon round */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 12.8px 9px #46bdc4;
}

.get-started {
  background: linear-gradient(90deg, #d724fc 12%, #7770ff 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent;
  display: inline-block;
  font-weight: bold;
}

.aboutus-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 1px solid #d724fc;
  border: 1px solid var(--Color-stroke, #9000ff54);
  border-radius: 16px;
  width: 50%;

  @media screen and (max-width: 767px) {
    gap: 12px;
    padding: 8px;
    padding-top: 48px;
    padding-bottom: 16px;
    min-width: 80%;
    position: relative;
  }
}

.aboutus-card:hover {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border-width: 2px;
  border-style: solid;
  border-image-source: linear-gradient(90deg, #d724fc 12%, #7770ff 100%);
  border-image-slice: 1;
  border-radius: 16px;
  overflow: hidden; /* Ensures border-radius works with border-image */
  width: 50%;
}

.ourmission-img-container {
  background: linear-gradient(272.44deg, #0eeea8 4.31%, #7770ff 129.99%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  border-radius: 14px;
  padding: 8px;

  @media screen and (max-width: 767px) {
    position: absolute;
    top: -20px;
    left: 20px;
  }
}
.solutions-container {
  display: flex;
  flex-wrap: wrap; /* Allows cards to wrap to the next row */
  justify-content: center; /* Centers the cards horizontally */
  gap: 20px; /* Space between cards */
  padding: 20px;
}

.solutions-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  /* background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  ); */
  border: 1px solid #d724fc;
  border-width: 1.11px, 1.11px, 1.11px, 1.11px;
  border-style: solid;
  border-color: var(--Color-stroke, #9000ff54);
  border-radius: 16px;
  /* width: 30%; */

  @media screen and (max-width: 767px) {
    width: 50%;
    padding: 6px;
    padding-top: 16px;
    gap: 12px;
  }
}

/* .solutions-card:hover {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border-width: 1.11px, 1.11px, 1.11px, 1.11px;
  border-style: solid;
  border-color: var(--Color-stroke, #9000ff54);
  border-radius: 16px;
  width: 30%;
  border: 1px solid #d724fc;
} */

.techstack-card {
  display: flex;
  flex-direction: row;
  gap: 36px;
  padding: 32px 24px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 1px solid #d724fc;
  border-width: 1.11px, 1.11px, 1.11px, 1.11px;
  border-style: solid;
  border-color: var(--Color-stroke, #9000ff54);
  border-radius: 56px;
  width: 55%;
  @media screen and (min-width: 768px) {
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.techstack-container {
  display: flex;
  flex-direction: column;
}

.round-gradient-circle {
  border: 1px violet solid;
  border-radius: 90px;
}

.impact-card {
  width: 30%;
  border-radius: 16px;
  position: relative;

  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
}

.impact-card:nth-child(2) {
  transform: translateY(120px); /* Moves the middle card 20px downward */
  @media screen and (max-width: 767px) {
    transform: translateY(0px);
  }
}

.case-study {
  width: calc(100% - 36px);
  position: absolute;
  bottom: 18px;
  left: 18px;
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--Color-stroke, #9000ff54);
}

.case-study:hover {
  width: calc(100% - 36px);
  position: absolute;
  bottom: 18px;
  left: 18px;
  border-radius: 16px;
  background: linear-gradient(272.44deg, #0eeea8 4.31%, #7770ff 129.99%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--Color-stroke, #9000ff54);
  backdrop-filter: blur(12.699999809265137px);
  opacity: 0.7;
}

.teamcard-container {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 80px 0px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    padding: 8px;
  }
}

.teamcard-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Edge, Safari */
}

.teamcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 1.5px solid var(--Color-stroke, #9000ff54);
  border-radius: 16px;
  min-width: 25%;
  gap: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  z-index: 10;

  @media screen and (max-width: 767px) {
    min-width: 95%;
  }
}

/* Hover Effect */
.teamcard:hover {
  transform: scale(1.3); /* Scales the card slightly on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Adds a stronger shadow on hover */
  z-index: 10;
}

.teamcard-image {
  width: 200px;
  transition: transform 0.3s ease; /* Adds smooth transition to the image as well */
  border-radius: 100px;
}

.teamcard-name {
  color: #fff;
  font-size: x-large;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.teamcard-title {
  color: #3cc8c8;
  font-size: large;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

/* Optional: Add a hover effect to the image (if desired) */
.teamcard:hover .teamcard-image {
  transform: scale(1.15); /* Scales the image slightly on hover */
  border-radius: 100px;
}

/* Container */
.defi-cards-container {
  display: flex;

  justify-content: center;
  padding: 20px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3.5rem;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 2rem;
  }
}

/* Individual Card */
.defi-card {
  display: flex;
  flex-direction: column;
  /* width: 30%; */
  background: #171547;
  /* linear-gradient(180deg, #1b1f38, #3a3f6e); */
  border: 1px solid var(--Color-stroke, #9000ff54);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;

  /* @media screen and (max-width: 767px) {
    min-width: 90%;
  } */
}

/* Card Hover Effect */
.defi-card:hover {
  /* transform: scale(1.05); */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  /* border: 1px solid #d724fc; */
}

/* Card Image */
.defi-card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 16px;
}

/* Card Content */
.defi-card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #ffffff;
}

/* Category */
.defi-card-category {
  font-size: 0.875rem;
  font-weight: 600;
  color: #3cc8c8;
}

/* Title */
.defi-card-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
}

/* Date */
.defi-card-date {
  font-size: 0.875rem;
  font-weight: 400;
  color: #a9a9a9;
}

/* Description */
.defi-card-description {
  font-size: 0.875rem;
  font-weight: 400;
  color: #d1d1d1;
  line-height: 1.5;
}

.enter-mail-textfield {
  border-radius: 50px;
  border: 0.61px solid #ffffff;
  outline: none !important;
}

.enter-mail-textfield:focus {
  outline: none !important;
}

.contactus-container {
  width: 100%;
  padding: 48px 96px;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 1px solid var(--Color-stroke, #9000ff54);
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  gap: 8rem;

  @media screen and (max-width: 767px) {
    padding: 16px 16px;
    gap: 1rem;
    align-items: center;
  }
}

.partner-card {
  width: 80%;
  background: linear-gradient(
    180deg,
    rgba(145, 0, 255, 0) 0%,
    rgba(145, 0, 255, 0.17) 100%
  );
  border: 1.45px solid var(--Color-stroke, #9000ff54);
  border-radius: 32px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

/* .custom-border-left {
  position: relative;
  border-top: 2px #9000ff54 solid;
  border-right: 2px #9000ff54 solid;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  width: 90%;
  height: 350px;
  display: flex; 
  align-items: flex-start; 
  justify-content: center;
  animation: animateBorder 3s forwards ease-out infinite;

  @media screen and (max-width: 767px) {
    height: 130px;
  }
} */

/* Define the animation */
/* @keyframes animateBorder {
  0% {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  50% {
    border-top-color: #9000ff54;
    border-right-color: transparent;
  }
  100% {
    border-top-color: #9000ff54;
    border-right-color: #9000ff54;
  }
} */

.top-div-container {
  display: flex; /* Flexbox for inner divs */
  justify-content: space-around; /* Space around the three divs */
  width: 100%; /* Ensure it spans the width of the parent */
  position: absolute; /* Position to align on the border */
  top: -50px; /* Adjust to position above the border */
  z-index: 10;

  @media screen and (max-width: 767px) {
    top: -30px;
  }
}

.bottom-div-container {
  display: flex; /* Flexbox for inner divs */
  justify-content: space-around; /* Space around the three divs */
  width: 100%; /* Ensure it spans the width of the parent */
  position: absolute; /* Position to align on the border */
  bottom: -155px; /* Adjust to position above the border */
  z-index: 10;
  @media screen and (max-width: 390px) {
    bottom: -84px;
  }
  @media screen and (min-width: 391px) and (max-width: 767px) {
    bottom: -64px;
  }
}

.bottom-div-right-container {
  display: flex; /* Flexbox for inner divs */
  justify-content: space-around; /* Space around the three divs */
  width: 100%; /* Ensure it spans the width of the parent */
  position: absolute; /* Position to align on the border */
  bottom: -127px; /* Adjust to position above the border */
  z-index: 10;

  @media screen and (max-width: 767px) {
    bottom: -60px;
  }
}

.service-img-div {
  background-color: #86d2d2; /* Background for visibility */
  box-shadow: 0px 3px 29.8px 0px #46bdc4;
  padding: 20px;
  border-radius: 60px; /* Add rounded corners */
  min-width: 50px; /* Ensure some width */
  text-align: center;
}

.service-title {
  background: linear-gradient(90deg, #d724fc 12%, #7770ff 100%);
  -webkit-background-clip: text; /* Clips the background to the text */
  -webkit-text-fill-color: transparent; /* Makes the text itself transparent to show the gradient */
  font-size: 20px; /* Adjust as needed */
  font-weight: bold; /* Optional for better visibility */
  text-align: center; /* Optional */

  @media screen and (max-width: 767px) {
    font-size: 10px;
  }
}

/* .custom-border-right {
  position: relative;
  float: right;
  border-left: 2px #9000ff54 solid;
  border-bottom: 2px #9000ff54 solid;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  width: 90%;
  height: 350px;
  animation: animateBorder 3s forwards ease-out infinite;

  @media screen and (max-width: 767px) {
    height: 130px;
  }
}

.custom-border-right::after {
  content: '';
  display: block; 
  margin-left: 125px;
  width: 68%; 
  border-bottom: 2px solid #9000ff54;
  @media screen and (min-width: 391px) and (max-width: 767px) {
    width: 75%;
  }
  @media screen and (max-width: 767px) {
    margin-left: 45px;
    width: 55%;
  }
} */
@keyframes animateBorderLeft {
  0% {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  50% {
    border-top-color: #9000ff54;
    border-left-color: transparent;
  }
  100% {
    border-top-color: #9000ff54;
    border-left-color: #9000ff54;
  }
}

@keyframes animateBorderRight {
  0% {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  50% {
    border-bottom-color: #9000ff54;
    border-right-color: transparent;
  }
  100% {
    border-bottom-color: #9000ff54;
    border-right-color: #9000ff54;
  }
}

.custom-border-left {
  position: relative;
  border-top: 2px #9000ff54 solid;
  border-right: 2px #9000ff54 solid;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  width: 90%;
  height: 350px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  animation: animateBorderLeft 3s forwards ease-out infinite;
  animation-delay: 2s;
  @media screen and (max-width: 767px) {
    height: 130px;
  }
}

.custom-border-right {
  position: relative;
  float: right;
  border-left: 2px #9000ff54 solid;
  border-bottom: 2px #9000ff54 solid;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  width: 90%;
  height: 350px;
  animation: animateBorderRight 3s forwards ease-out infinite;
  animation-delay: 4s;

  @media screen and (max-width: 767px) {
    height: 130px;
  }
}

.custom-border-right::before {
  content: '';
  display: block;
  margin-left: 125px;
  width: 68%;
  border-bottom: 2px solid #9000ff54;
  animation: animateBorderRight 3s backwards ease-out infinite;
  animation-delay: 2s; /* Delay to start after the left animation ends */

  @media screen and (min-width: 391px) and (max-width: 767px) {
    width: 75%;
  }
  @media screen and (max-width: 767px) {
    margin-left: 45px;
    width: 55%;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  scrollbar-width: none; /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.aboutus-card-new {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border: 1px solid var(--Color-stroke, #9000ff54);
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    gap: 12px;
    padding: 8px;
    padding-top: 48px;
    padding-bottom: 16px;
    min-width: 80%;
    position: relative;
  }
}

.aboutus-card-new:hover {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border: 2px solid;
  border-image-source: linear-gradient(90deg, #d724fc 12%, #7770ff 100%);
  border: none; /* Remove border for cleaner appearance */
  overflow: hidden; /* Ensures border-radius works */
  position: relative;
}


@keyframes border-slide {
  0% {
      border-width: 0 0 0 0;
  }
  25% {
      border-width: 4px 0 0 0;
  }
  50% {
      border-width: 4px 4px 0 0;
  }
  75% {
      border-width: 4px 4px 4px 0;
  }
  100% {
      border-width: 4px 4px 4px 4px;
  }
}
.animate-border-slide {
  animation: border-slide 3s linear infinite;
}