.name {
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
}

.value {
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
}

.password-toggle-btn {
    background: none;
    border: none;
    color: #A0A0A0;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.profileTitle {
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(60% + 48px);
}

.profileCard {
    width: 60%;
    padding:24px;
    border-radius: 12px;
    background-color: #1C1C1C;
    gap:24px;
}

.updateButton {
    background-color: #E31892;
    border:none;
}

.cancelButton {
    border: 1px #E31892 solid;
    background-color: #1C1C1C;
    
}

.updateButton, .cancelButton {
    height:36px;
    font-size:12px;
    font-weight:500;
    border-radius: 16px;
    color:#FFF;
    padding: 12px 24px;
    cursor: pointer;
}

.upgradeButton {
    background-color: white;
    border:none;
    height:36px;
    font-size:12px;
    font-weight:500;
    border-radius: 16px;
    color:blue;
    padding: 12px 24px;
    cursor: pointer;
}

.inputTextField {
    height:36px;
    background-color: #1C1C1C;
    border: 1px #E31892 solid;
    border-radius: 12px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    padding-left: 12px;
}

.inputTextField:focus {
    outline: none;
}
.updatebuttons {
    width: calc(60% + 48px) !important
}

@media screen and (max-width:767px) {
    .mobileProfile {
        /* width:85% */
    }
    .profileCard {
        width: 80%;
        padding:24px;
        border-radius: 12px;
        background-color: #1C1C1C;
        gap:24px;
    }
    .profileTitle {
        font-size: 14px;
        font-weight: 700;
        color: #FFF;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: calc(80% + 48px);
    }
    .updatebuttons {
        width: calc(80% + 48px) !important
    }
}