.dashboard {
  /* padding: 24px; */
  overflow-y: auto;
}

.dashboardtype {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}
.dashboarddetails {
  background-color: #292929;
  padding: 24px;
  /* border-radius: 16px; */
}

.dashboard::-webkit-scrollbar {
  display: none;
}

.leftalign {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* Table.css */

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  padding: 16px;
  text-align: center;
}
.table td {
  padding: 12px; /* Adjust padding as needed */
  text-align: center;
}

.table th {
  color: #878787;
  font-size: 18px;
  font-weight: 500;
}

/* Adjust width and spacing between columns */
.table th,
.table td {
  /* width: calc(100% / 3); */
}

/* Alternative approach: Equal spacing with flexbox */
.table tr {
  display: flex;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.table th,
.table td {
  flex: 1;
}

.plain-button {
  background-color: #16c09861;
  color: white; /* Set the text color */
  border: 1px solid #00b087;
  padding: 4px 16px; /* Adjust padding as needed */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 0.9rem; /* Set font size */
  font-weight: 500; /* Set font weight */
}

.plain-button-remove {
  background-color: #ffc5c5;
  border: 1px solid #df0404;
  color: #df0404; /* Set the text color */
  padding: 4px 16px; /* Adjust padding as needed */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 0.9rem; /* Set font size */
  font-weight: 500; /* Set font weight */
}
.plain-button-cycle {
  background-color: #080808;
  border: 1px solid #5932ea;
  color: #f2eaea; /* Set the text color */
  padding: 4px 16px; /* Adjust padding as needed */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 0.9rem; /* Set font size */
  font-weight: 500; /* Set font weight */
}

.plain-button-add {
  background-color: #7636ce;
  border: none;
  color: #ffffff; /* Set the text color */
  padding: 8px 16px; /* Adjust padding as needed */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4px; /* Add border radius for rounded corners */
  font-size: 0.9rem; /* Set font size */
  font-weight: 500;
}

.searchInput {
  padding: 8px;
  border-radius: 8px;
  border: none;
  color: #fff;
  background-color: #292929;
}
.searchInput:focus {
  outline: none;
}

.profit {
  color: #88f8c2;
}
.loss {
  color: #ff8181;
}

.profit,
.loss {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modalPopup {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
  width: '300px';
  padding: '20px';
  border-radius: '8px';
  box-shadow: '0 4px 8px rgba(0, 0, 0, 0.2)';
  background-color: '#fff';
}

.closeIcon {
  height: 25px;
  color: #FFF;
  border: none;
  font-size: 20px;
  font-weight: 400;
}

.username {
  font-size: 20px;
  font-weight: 400;
  color: #FFF;
}

.save {
  height: 36px;
  background: linear-gradient(270deg, #0EEEA8 12.01%, #7770FF 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 2rem;
  padding: 0px 1rem;
  cursor: pointer;
}

.deleteButton {
  height: 36px;
  background: linear-gradient(90deg, #D724FC 12%, #7770FF 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 2rem;
  padding: 0px 1rem;
  cursor: pointer;
}

.fieldName {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}

.numberInput {
  background-color: var(--White-10, #FFFFFF1A);
  width: 96px;
  height: 36px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  padding: 0px 12px;
  color: #FFF;
}

.dropDownInput {
  background-color: #FFFFFF1A;
  width: 120px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  border-radius: 50px;
  padding: 0px 12px;
  color: #FFF;
}

.dropDownInput ::selection {
  background-color: #212f4c;
}

.paginationResultText {
  color: #b5b7c0;
  font-weight: 500;
  font-size: 0.9rem;
}

.paginationbutton {
  color: #404b52;
  background-color: #f5f5f5;
  border: 1px solid #dfdcdc;
  padding: 8px 12px;
  border-radius: 4px;
}

.paginationbuttonActive {
  background-color: #5932ea;
  color: white;
  border: 1px solid #5932ea;
}

.dropDownInput:focus,
.numberInput:focus {
  outline: none;
}

.skeleton-container {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.skeleton {
  /* background-color: #f0f0f0; */
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.skeleton-line {
  height: 20px; /* Adjust the height of each loading line */
  margin-bottom: 10px;
  background-color: #252525;
}
.header-name {
  font-weight: bold;
  font-size: 18px;
  color: FFF;
}
.bold-and-large {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}

.userTable {
  background-color: #212f4c;
  /* box-shadow: 0px 10px 60px 0px #2170CE80; */
  /* border-radius: 8px; */
}
/* .userTable tr {
  padding: 0 1rem;
}
.userTable td {
  text-align: start;
} */

.allusers {
  font-size: 1.5rem;
  font-weight: 600;
  color: #faeeee;
}

.activemem {
  font-size: 1rem;
  font-weight: 400;
  color: #16c098;
}

@media screen and (max-width: 767px) {
  .mobileTable {
    /* max-width:330px; */
    overflow-x: auto; /* Display horizontal scrollbar */
    white-space: nowrap;
  }
  .dashboard {
    padding: 0px !important;
    overflow-y: auto;
  }
  .mobileWidthhun {
    width: calc(100%-16px) !important;
  }

  .searchInput {
    padding: 8px;
    border-radius: 8px;
    border: none;
    color: #fff;
    background-color: #292929;
    width: 100% !important;
  }
}

.userDataTableHeader {
  font-size: 2rem;
  font-weight: 500;
  color: #33cccc !important;
  text-transform: capitalize;
}
.UserdetailsField {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
/* Animation for loading lines */
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.skeleton-line {
  animation: loading 1.5s infinite;
}

@media screen and (min-width: 768px) {
  .currencyTable {
    background-color: #161617;
    box-shadow: 0px 10px 60px 0px #7636ce80;
    border-radius: 16px;
    padding: 12px 0px;
  }
  .currencyTable .table tr {
    display: flex;
    color: #a9acbb;
    font-size: 0.7rem;
    font-weight: 400;
    border-bottom: 0.05px #494a4e solid;
  }
  .userDataTable .table tr {
    display: flex;
    color: #a9acbb;
    font-size: 0.7rem;
    font-weight: 400;
    border-bottom: 0.05px #494a4e solid;
  }
  .topdivs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  .topfeatures {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }
}
@media screen and (max-width: 767px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    /* width: 100%; */
  }

  .table tr {
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: #000;
    border-radius: 8px;
    padding: 12px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    text-align: right;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }

  .profit,
  .loss {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
  .table td {
    /* width: calc(100% / 3); */
  }
  .topdivs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
  }
  .topfeatures {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 36px;
    width: 100%;
  }
}
