.dashboardIcon {
    /* background-color: #B0B0B01A; */
    padding: 10px;
    border-radius: 14px;
    border:2.5px #FFF solid;
}

.dashboardFieldName {
    font-size: 1rem;
    font-weight: 400;
    color: #FFFFFF99;
}

.balancePrice {
    font-size: 1rem;
    font-weight: 600;
    color:#FFEF60
}

.dashboardFieldValue {
    font-size: 1.2rem;
    font-weight: 700;
    color:#FFF
}

.dashboardFilterName {
    font-size: 12px;
    font-weight: 400;
    color:#FFF
}

.dashboardDropdown {
    background-color: #1C1C1C;
    border:1px #D5D5D8 solid;
    border-radius: 24px;
    padding:6px 12px;
    color:#D5D5D8;
    min-width:100px;
}

.dashboardDropdown:focus {
    outline: none;
}

.walletname {
    font-weight: 600;
    font-size:1.8rem;
    color:#F2E7E7;
    margin-bottom: 24px;
}
.walletheader{
    color:#E31892 !important;
    font-weight: 400 !important;
    font-size: 0.5 rem !important;
}
.walletRow:hover {
    padding:16 !important;
    box-shadow: 0px 4px 38px 0px #6418C338;
}

.walletTable .table tr {
    font-size: 0.5 rem !important;
}

/* WebKit scrollbar for tbody */
tbody::-webkit-scrollbar {
    width: 4px; /* width of the scrollbar */
    border-radius: 16px;
  }
  
  /* Track */
  tbody::-webkit-scrollbar-track {
    background: #292929; /* color of the track */
  }
  
  /* Handle */
  tbody::-webkit-scrollbar-thumb {
    background: #E31892; /* color of the handle */
    border-radius: 8px;
  }
  
  /* Handle on hover */
  tbody::-webkit-scrollbar-thumb:hover {
    background: #E31892; /* color of the handle on hover */
  }


@media screen and (max-width:767px) {
    .balanceCards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
    }
    .balanceCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
        padding: 32px 24px;
        background: linear-gradient(258.35deg, #292929 -0.64%, #161516 96.47%);
        min-width: 300px;
        border-radius: 8px;
        box-shadow: 0px 9px 20px 0px #000000;
        min-height: 120px;
    }
    .featureCard {
        flex:1;
        background-color: #080808;
        box-shadow:0px 4px 38px 0px #6418C338;
        padding: 24px;
        border-radius: 8px;
        height: 300px;
        width: -webkit-fill-available;
    }
    .featureName {
        justify-content: "flex-start";
        align-items: "center";
        color: "#E31892";
        font-size: 1rem;
        font-weight: 500;
        color:#FFF;
    }
    .featureCards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        gap:20px;
    }
    .walletTable table tr {
        background-color: #292929;
    }
}

@media screen and (min-width:768px) {
    .balanceCards {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 50px;
    }
    .desktopgraph {
        height: 300px !important;
    }
    .balanceCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
        padding: 32px 24px;
        background: linear-gradient(258.35deg, #292929 -0.64%, #161516 96.47%);
        min-width: 300px;
        border-radius: 8px;
        box-shadow: 0px 9px 20px 0px #000000;
        min-height: 120px;
    }
    .subscriptionPeriodCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        padding: 32px 24px;
        margin: 5px 5px 5px 50px;
        background: linear-gradient(258.35deg, #292929 -0.64%, #161516 96.47%);
        max-width: 150px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba(150, 150, 255, 0.50);
        max-height: 70px;
    }
    .featureCards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:stretch;
        gap:20px;
    }
    .featureCard {
        flex:1;
        background-color: #080808;
        box-shadow:0px 4px 38px 0px #6418C338;
        padding: 24px;
        border-radius: 8px;
        /* height: 300px; */
    }
    .featureName {
        justify-content: "flex-start";
        align-items: "center";
        color: "#E31892";
        font-size: 1rem;
        font-weight: 500;
        color:#FFF;
    }

    .walletbody {
        /* height:220px !important; */
    }
    
}